import React, { useState, useEffect } from "react"
import { AppCtx, useContextState } from "@components/contexted"
// const {loaded} = useActions(AppCtx, 'loaded')
import {loader} from "./loaderInit.modal.scss"


const MainAppLoader = () => {
  const [loaded, setter] = useState(false)
  const { imBusy } = useContextState(AppCtx, "imBusy")

  useEffect(() => {
    if (imBusy === false) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        setter(true)
      }, 333)
    }
  }, [imBusy])

  return (
    <React.Fragment>
      {!loaded && (
        <div className={"loader__wrap"}>
          {" "}
          <div className={loader}>Loading...</div>
          {/* Czuj sie jak u siebie w domu! */}
        </div>
      )}
    </React.Fragment>
  )
}

export default MainAppLoader
